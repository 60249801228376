import { DeleteOutlined, FilterOutlined } from "@ant-design/icons";
import { Button, PageHeader, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";

import { request } from "../../utils/request";

export const Complaints: FC = () => {
  const [complaints, setComplaints] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState<{ type?: string; data?: string }>({});

  const getComplaints = async (params?: { type?: string; data?: string }) => {
    const response = await request.post("/complaint/list", params);
    setComplaints(response.data);
    setLoading(false);
  };

  const deleteComplaint = async (id: number) => {
    try {
      setLoading(true);
      await request.delete("/complaint", {
        data: { id },
      });
      await getComplaints();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getComplaints(params);
  }, [params]);

  const columns: ColumnsType<any> = [
    {
      width: 50,
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Автор",
      dataIndex: "senderProfileId",
      key: "senderProfileId",
      render: (_, { senderProfileId }) => {
        return (
          <a target="_blank" href={`https://amorus.ru/profile/${senderProfileId}`} rel="noreferrer">
            {senderProfileId}
          </a>
        );
      },
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      render: (_, { type }) => {
        return type;
      },
    },
    {
      title: "Сообщение",
      dataIndex: "message",
      key: "message",
      render: (_, { message }) => {
        return message || "---";
      },
    },
    {
      title: "Данные",
      dataIndex: "data",
      key: "data",
      render: (_, { data, type }) => {
        if (type === "profile" && data) {
          return (
            <a target="_blank" href={`https://amorus.ru/profile/${data}`} rel="noreferrer">
              Профиль: {data}
            </a>
          );
        }
        if (type === "notice" && data) {
          return `ОБЪЯВЛЕНИЕ ID: ${data}`;
        }

        return "---";
      },
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, { createdAt }) => {
        return moment(createdAt).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      width: 200,
      title: "Управление",
      key: "action",
      render: (_, { id, type, data }) => (
        <Space>
          <Button
            danger={Boolean(params.type || params.data)}
            size="small"
            onClick={() => {
              if (params.type || params.data) {
                setParams({});
              } else {
                setParams({ type, data });
              }
            }}
          >
            <FilterOutlined />
          </Button>
          <Popconfirm
            okType="danger"
            placement="leftTop"
            title="Точно отменить?"
            onConfirm={() => deleteComplaint(id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button loading={loading} size="small" danger={true} icon={<DeleteOutlined />}>
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Жалобы" />

      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 300 }}
        pagination={false}
        columns={columns}
        dataSource={complaints}
      />
    </div>
  );
};
