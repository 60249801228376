import { SaveOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, notification, PageHeader, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "../../components/spinner";
import { request } from "../../utils/request";

export const RatingsReview: FC = () => {
  const [site, setSite] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { id, site_id } = useParams();
  const navigate = useNavigate();

  const getPages = async () => {
    const response = await request.post("/ratings/review/get", { id: Number(id) });
    setSite(response.data);
    setLoading(false);
  };

  const submit = async (fields: any) => {
    try {
      if (id) {
        await request.post("/ratings/review/update", { id: Number(id), ...fields });
      } else {
        const { data } = await request.post("/ratings/review/create", { site_id: Number(site_id), ...fields });
        navigate(`/ratings-review/${site_id}/${data.id}`);
      }

      notification.success({ message: "Ура!", description: "Сохранили успешно" });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      getPages();
    } else {
      setSite({});
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <PageHeader ghost={false} title={`${id ? "Редактирование" : "Добавление"} отзыва`} />
      {loading || !site ? (
        <Spinner />
      ) : (
        <Row>
          <Col span={9}>
            <Form initialValues={site} layout="vertical" onFinish={submit}>
              <Form.Item name="name" label="Имя" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="text" label="Сообщение" rules={[{ required: true }]}>
                <Input.TextArea rows={10} />
              </Form.Item>
              <Form.Item name="rating" label="Рейтинг" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="thumb_up_count" label="Палец вверх" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="thumb_down_count" label="Палец вниз" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                Сохранить
              </Button>
            </Form>
          </Col>
        </Row>
      )}
    </div>
  );
};
