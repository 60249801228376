import { CommentOutlined, DeleteOutlined, EditOutlined, PlusOutlined, ProfileOutlined } from "@ant-design/icons";
import { Button, Image, PageHeader, Popconfirm, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getFullFileUrl } from "../../utils/getFullFileUrl";
import { request } from "../../utils/request";

export const RatingsSites: FC = () => {
  const [pages, setPages] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { source } = useParams();
  const navigate = useNavigate();

  const getPages = async () => {
    const response = await request.post("/ratings/sites/get", { source: Number(source) });
    setPages(response.data);
    setLoading(false);
  };

  const deletePage = async (id: number) => {
    await request.post("/ratings/site/delete", { id });
    await getPages();
  };

  useEffect(() => {
    getPages();
  }, []);

  const columns: ColumnsType<any> = [
    {
      width: 50,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Место",
      dataIndex: "place",
      key: "id",
    },
    {
      title: "Лого",
      dataIndex: "logo",
      key: "id",
      render(logo) {
        return <Image width={70} src={getFullFileUrl(logo)} alt="Лого" />;
      },
    },
    {
      title: "Изображение",
      dataIndex: "image",
      key: "id",
      render(logo) {
        return <Image width={70} src={getFullFileUrl(logo)} alt="Лого" />;
      },
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "id",
    },
    {
      title: "Алиас",
      dataIndex: "alias",
      key: "id",
    },
    {
      title: "Рейтинг",
      dataIndex: "rating",
      key: "id",
    },
    {
      title: "Удобство",
      dataIndex: "convenience_rating",
      key: "id",
    },
    {
      title: "Сервисы",
      dataIndex: "services_rating",
      key: "id",
    },
    {
      title: "Техподдержка",
      dataIndex: "support_rating",
      key: "id",
    },
    {
      title: "Аудитория",
      dataIndex: "auditory",
      key: "id",
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "id",
      render(description) {
        return description ? <Tag color="green">Заполнено</Tag> : <Tag color="red">Не заполнено</Tag>;
      },
    },
    {
      width: 200,
      title: "Действия",
      key: "action",
      render: (_, { id, reviews_count, profiles_count }) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            type="primary"
            href={`/ratings-site/${source}/${id}`}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/ratings-site/${source}/${id}`);
            }}
          >
            Редактировать
          </Button>
          <Button
            icon={<ProfileOutlined />}
            type="dashed"
            size="small"
            href={`/ratings-profiles/${id}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/ratings-profiles/${id}`);
            }}
          >
            Профиля ({profiles_count})
          </Button>
          <Button
            icon={<CommentOutlined />}
            type="dashed"
            size="small"
            href={`/ratings-reviews/${id}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/ratings-reviews/${id}`);
            }}
          >
            Отзывы ({reviews_count})
          </Button>
          <Popconfirm title="Уверен?" onConfirm={() => deletePage(id)}>
            <Button danger icon={<DeleteOutlined />} size="small">
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Сайты источника"
        extra={
          <Button type="primary" href={`/ratings-site/${source}`} icon={<PlusOutlined />}>
            Добавить
          </Button>
        }
      />
      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 300 }}
        pagination={false}
        columns={columns}
        dataSource={pages}
      />
    </div>
  );
};
