import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Image, PageHeader, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, Fragment, useEffect, useState } from "react";

import { isPlatform } from "../../utils/isPlatform";
import { request } from "../../utils/request";

export const Confirmations: FC = () => {
  const [confirmations, setConfirmations] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [images, setImages] = useState([]);

  const getConfirmations = async () => {
    const response = await request.get("/profile-confirmation");
    setConfirmations(response.data);
    setLoading(false);
  };

  const confirmed = async (id: number, confirmed: boolean, type: string) => {
    try {
      setLoading(true);
      await request.post("/profile-confirmation/confirmation", {
        id,
        confirmed,
        type,
      });
      await getConfirmations();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getConfirmations();
  }, []);

  const columns: ColumnsType<any> = [
    {
      width: 50,
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      width: 100,
      title: "ID профиля",
      dataIndex: "profileId",
      key: "profileId",
      render: (_, { profileId }) => {
        return (
          <a target="_blank" href={`https://cp.amorus.ru/profile/${profileId}`} rel="noreferrer">
            {profileId}
          </a>
        );
      },
    },
    {
      width: 80,
      title: "Подтверждение",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => {
        return <Image width={160} src={`${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${image}`} />;
      },
    },
    {
      width: 80,
      title: "Фотографии",
      dataIndex: "image",
      key: "image",
      render: (_, { profile }) => {
        return profile.avatar ? (
          <Fragment>
            <Image
              preview={{ visible: false }}
              width={160}
              src={`${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${profile.avatar.image}`}
              onClick={() => {
                setVisible(true);
                setImages(profile.photos || []);
              }}
            />
          </Fragment>
        ) : null;
      },
    },
    {
      title: "Пол",
      dataIndex: "sex",
      key: "sex",
      render: (_, { profile: { sex } }) => {
        return sex === "male" ? "Мущына" : "Бэйбачка";
      },
    },
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      render: (_, { profile: { name } }) => {
        return name;
      },
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, { createdAt }) => {
        return moment(createdAt).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      width: 200,
      title: "Управление",
      key: "action",
      render: (_, { id }) => (
        <Space>
          <Popconfirm
            placement="leftTop"
            title="Точно подтвердить?"
            onConfirm={() => confirmed(id, true, "photo")}
            okText="Да"
            cancelText="Нет"
          >
            <Button loading={loading} icon={<CheckOutlined />} type="primary" size="small">
              Подтвердить фото
            </Button>
          </Popconfirm>
          <Popconfirm
            placement="leftTop"
            title="Точно подтвердить?"
            onConfirm={() => confirmed(id, true, "profile")}
            okText="Да"
            cancelText="Нет"
          >
            <Button loading={loading} icon={<CheckOutlined />} type="primary" size="small">
              Подтвердить аккаунт
            </Button>
          </Popconfirm>
          <Popconfirm
            placement="leftTop"
            title="Точно подтвердить?"
            onConfirm={() => confirmed(id, true, "general")}
            okText="Да"
            cancelText="Нет"
          >
            <Button loading={loading} icon={<CheckOutlined />} type="primary" size="small">
              Подтвердить всё
            </Button>
          </Popconfirm>
          <Popconfirm
            okType="danger"
            placement="leftTop"
            title="Точно отменить?"
            onConfirm={() => confirmed(id, false, "general")}
            okText="Да"
            cancelText="Нет"
          >
            <Button loading={loading} size="small" danger={true} icon={<DeleteOutlined />}>
              Отклонить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Подтверждения" />

      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 300 }}
        pagination={false}
        columns={columns}
        dataSource={confirmations}
      />

      <div style={{ display: "none" }}>
        <Image.PreviewGroup preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}>
          {((images as []) || []).map(({ image }, index) => (
            <Image key={index} src={`${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${image}`} />
          ))}
        </Image.PreviewGroup>
      </div>
    </div>
  );
};
