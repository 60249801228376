import { SaveOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Image, Input, notification, PageHeader, Row, Upload } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "../../components/spinner";
import { getFullFileUrl } from "../../utils/getFullFileUrl";
import { request } from "../../utils/request";

export const RatingsProfile: FC = () => {
  const [site, setSite] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const { id, site_id } = useParams();
  const navigate = useNavigate();

  const getPages = async () => {
    const response = await request.post("/ratings/profile/get", { id: Number(id) });
    setSite(response.data);
    setLoading(false);
  };

  const submit = async (fields: any) => {
    try {
      if (id) {
        await request.post("/ratings/profile/update", { id: Number(id), ...fields });
      } else {
        const { data } = await request.post("/ratings/profile/create", { site_id: Number(site_id), ...fields });
        navigate(`/ratings-profile/${site_id}/${data.id}`);
      }

      notification.success({ message: "Ура!", description: "Сохранили успешно" });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (id) {
      getPages();
    } else {
      setSite({});
      setLoading(false);
    }
  }, []);

  return (
    <div>
      <PageHeader ghost={false} title={`${id ? "Редактирование" : "Добавление"} профиля`} />
      {loading || !site ? (
        <Spinner />
      ) : (
        <Row>
          <Col span={9}>
            <Form initialValues={site} layout="vertical" onFinish={submit}>
              <Form.Item name="name" label="Имя" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item dependencies={["image"]} noStyle>
                {({ setFieldValue, getFieldValue }) => (
                  <Form.Item name="image" label="Фотография" dependencies={["image"]} rules={[{ required: true }]}>
                    <Upload
                      maxCount={1}
                      multiple={false}
                      action="https://api.amorus.ru/files/upload-off-resize"
                      listType="text"
                      onChange={(file: any) => {
                        if (file.file.response?.path) {
                          setFieldValue("image", file.file.response?.path);
                        }
                      }}
                    >
                      {getFieldValue("image") ? (
                        <Image preview={false} width={80} src={getFullFileUrl(getFieldValue("image"))} />
                      ) : (
                        <Button icon={<UploadOutlined />}>Загрузить</Button>
                      )}
                    </Upload>
                  </Form.Item>
                )}
              </Form.Item>
              <Form.Item name="age" label="Возраст" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Button type="primary" icon={<SaveOutlined />} htmlType="submit">
                Сохранить
              </Button>
            </Form>
          </Col>
        </Row>
      )}
    </div>
  );
};
