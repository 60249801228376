import { Button, Card, DatePicker, Form, Image, Input, notification, PageHeader, Select, Space } from "antd";
import moment from "moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Spinner } from "../../components/spinner";
import { IProfile } from "../../interfaces/profile";
import { getFullFileUrl } from "../../utils/getFullFileUrl";
import { request } from "../../utils/request";

export const Profile: FC = () => {
  const [profile, setProfile] = useState<IProfile | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const { id } = useParams();

  const getProfile = async () => {
    const response = await request.get(`/profile/detail`, {
      params: { id },
    });
    setProfile(response.data);
    setLoading(false);
  };

  const save = async (values: any) => {
    try {
      const payload = {
        id: Number(id),
        sex: values.sex,
        confirmed: values.confirmed,
        photo_confirmed: values.photo_confirmed,
        name: values.name,
        age: Number(values.age),
        about: values.about,
        top_100_start_date: values.top_100_start_date
          ? moment(values.top_100_start_date, "YYYY/MM/DD HH:mm:ss").format("YYYY/MM/DD HH:mm:ss")
          : null,
        top_100_end_date: values.top_100_end_date
          ? moment(values.top_100_end_date, "YYYY/MM/DD HH:mm:ss").format("YYYY/MM/DD HH:mm:ss")
          : null,
        top_start_date: values.top_start_date
          ? moment(values.top_start_date, "YYYY/MM/DD HH:mm:ss").format("YYYY/MM/DD HH:mm:ss")
          : null,
        top_end_date: values.top_end_date
          ? moment(values.top_end_date, "YYYY/MM/DD HH:mm:ss").format("YYYY/MM/DD HH:mm:ss")
          : null,
        premium_date: values.premium_date
          ? moment(values.premium_date, "YYYY/MM/DD HH:mm:ss").format("YYYY/MM/DD HH:mm:ss")
          : null,
        createdAt: moment(values.createdAt, "YYYY/MM/DD HH:mm:ss").format("YYYY/MM/DD HH:mm:ss"),
        up_date: values.up_date ? moment(values.up_date, "YYYY/MM/DD HH:mm:ss").format("YYYY/MM/DD HH:mm:ss") : null,
      };

      await request.post("/profile/administrator/update", payload);
      notification.success({ message: "Профиль обновлен" });
    } catch (err) {
      console.error(err);
    }
  };

  const deleteProfilePhoto = async (path: string) => {
    if (!profile) {
      return null;
    }

    try {
      await request.delete("/profile-photos/delete", {
        data: { userId: profile.userId, path },
      });
      await getProfile();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getProfile();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <div>
      <PageHeader
        ghost={false}
        title={`Редактирование профиля: ${id} ${profile ? `(${profile.name})` : ""}`}
        extra={
          profile ? (
            <Button target="_blank" href={`https://amorus.ru/profile/${profile.id}`}>
              Открыть на сайте
            </Button>
          ) : null
        }
      />

      {loading ? (
        <Spinner />
      ) : profile ? (
        <div>
          <Card>
            {profile.photos.length ? (
              <Fragment>
                <Space>
                  {profile.photos.map(({ id, image }) => (
                    <div key={id}>
                      <Space direction="vertical">
                        <Image
                          style={{
                            border: "1px solid #ccc",
                            padding: 4,
                          }}
                          src={getFullFileUrl(image)}
                          height={100}
                        />
                        <Button
                          block
                          onClick={() => {
                            deleteProfilePhoto(image);
                          }}
                        >
                          Удалить
                        </Button>
                      </Space>
                    </div>
                  ))}
                </Space>
                <br />
                <br />
              </Fragment>
            ) : null}

            <Form
              style={{ maxWidth: 600 }}
              onFinish={save}
              layout="vertical"
              initialValues={{
                name: profile.name,
                age: profile.age,
                confirmed: profile.confirmed,
                photo_confirmed: profile.photo_confirmed,
                sex: profile.sex,
                about: profile.about,
                top_start_date: profile.top_start_date ? moment(profile.top_start_date) : undefined,
                top_end_date: profile.top_end_date ? moment(profile.top_end_date) : undefined,
                top_100_start_date: profile.top_100_start_date ? moment(profile.top_100_start_date) : undefined,
                top_100_end_date: profile.top_100_end_date ? moment(profile.top_100_end_date) : undefined,
                premium_date: profile.premium_date ? moment(profile.premium_date) : undefined,
                up_date: profile.up_date ? moment(profile.up_date) : undefined,
                createdAt: moment(profile.createdAt),
              }}
            >
              <Space>
                <Form.Item label="Имя" name="name" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item name="createdAt" label="Дата создания">
                  <DatePicker format="DD.MM.YYYY" placeholder="Выбрать дату" />
                </Form.Item>
              </Space>

              <Form.Item label="О себе" name="about">
                <Input />
              </Form.Item>

              <Form.Item label="Возраст" name="age">
                <Input />
              </Form.Item>

              <Form.Item name="confirmed" label="Аккаунт подтвержден" rules={[{ required: true }]}>
                <Select placeholder="Выберите">
                  <Select.Option value={true}>Да</Select.Option>
                  <Select.Option value={false}>Нет</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="photo_confirmed" label="Фото подтверждено" rules={[{ required: true }]}>
                <Select placeholder="Выберите">
                  <Select.Option value={true}>Да</Select.Option>
                  <Select.Option value={false}>Нет</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item name="sex" label="Пол" rules={[{ required: true }]}>
                <Select placeholder="Выберите">
                  <Select.Option value="male">Мужик</Select.Option>
                  <Select.Option value="female">Тёлочка</Select.Option>
                </Select>
              </Form.Item>

              <Space>
                <Form.Item name="top_100_start_date" label="ТОП 100 с:">
                  <DatePicker format="DD.MM.YYYY" placeholder="Выбрать дату" />
                </Form.Item>
                <Form.Item name="top_100_end_date" label="ТОП 100 до:">
                  <DatePicker format="DD.MM.YYYY" placeholder="Выбрать дату" />
                </Form.Item>
                <Form.Item name="top_start_date" label="ТОП с:">
                  <DatePicker format="DD.MM.YYYY" placeholder="Выбрать дату" />
                </Form.Item>
                <Form.Item name="top_end_date" label="ТОП до:">
                  <DatePicker format="DD.MM.YYYY" placeholder="Выбрать дату" />
                </Form.Item>
              </Space>

              <Space>
                <Form.Item name="premium_date" label="Премиум до:">
                  <DatePicker format="DD.MM.YYYY" placeholder="Выбрать дату" />
                </Form.Item>
                <Form.Item name="up_date" label="Поднятие в поиске">
                  <DatePicker format="DD.MM.YYYY" placeholder="Выбрать дату" />
                </Form.Item>
              </Space>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </div>
      ) : null}
    </div>
  );
};
