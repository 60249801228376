import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Image, PageHeader, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { getFullFileUrl } from "../../utils/getFullFileUrl";
import { request } from "../../utils/request";

export const RatingsProfiles: FC = () => {
  const [pages, setPages] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { site_id } = useParams();
  const navigate = useNavigate();

  const getPages = async () => {
    const response = await request.post("/ratings/profiles/get", { site_id: Number(site_id) });
    setPages(response.data);
    setLoading(false);
  };

  const deletePage = async (id: number) => {
    await request.post("/ratings/profile/delete", { id });
    await getPages();
  };

  useEffect(() => {
    getPages();
  }, []);

  const columns: ColumnsType<any> = [
    {
      width: 50,
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      width: 80,
      title: "Изображение",
      dataIndex: "image",
      key: "id",
      render(logo) {
        return <Image width={70} src={getFullFileUrl(logo)} alt="Лого" />;
      },
    },
    {
      width: 180,
      title: "Имя",
      dataIndex: "name",
      key: "id",
    },
    {
      title: "Возраст",
      dataIndex: "age",
      key: "id",
    },
    {
      width: 200,
      title: "Действия",
      key: "action",
      render: (_, { id }) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            type="primary"
            href={`/ratings-profile/${site_id}/${id}`}
            size="small"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/ratings-profile/${site_id}/${id}`);
            }}
          >
            Редактировать
          </Button>
          <Popconfirm title="Уверен?" onConfirm={() => deletePage(id)}>
            <Button danger icon={<DeleteOutlined />} size="small">
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <PageHeader
        ghost={false}
        title="Профиля"
        extra={
          <Button type="primary" href={`/ratings-profile/${site_id}`} icon={<PlusOutlined />}>
            Добавить
          </Button>
        }
      />
      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 300 }}
        pagination={false}
        columns={columns}
        dataSource={pages}
      />
    </Fragment>
  );
};
