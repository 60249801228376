import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, PageHeader, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { request } from "../../utils/request";
import ModalAdd from "./modalAdd";
import cls from "./style.module.scss";

interface IColum {
  text: string | null;
  date: string | null;
  id: number;
  amountOfCosts: number;
}

export const Bank: FC = () => {
  const [searchParams] = useSearchParams();
  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;

  const [info, setInfo] = useState({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });
  const [isModalVisible, setIsModalVisible] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  const getInfo = async () => {
    const response = await request.post("/administrator-bank-information/list", {
      offset: 50 * (page - 1),
    });
    setInfo(response.data);
    setLoading(false);
  };

  useEffect(() => {
    getInfo();
  }, [page]);

  const columns: ColumnsType<IColum> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Сумма ₽",
      dataIndex: "amountOfCosts",
      key: "id",
      render: (_, profile) => (
        <div>
          {profile.amountOfCosts.toLocaleString("ru-RU", {
            style: "currency",
            currency: "RUB",
          })}
        </div>
      ),
    },
    {
      title: "Дата расхода",
      dataIndex: "date",
      key: "id",
      render: (_, profile) => moment(profile.date).format("DD.MM.YYYY"),
    },
    {
      title: "Описание",
      dataIndex: "text",
      key: "id",
      render: (_, profile) => profile.text || "-",
    },
    {
      title: "Действия",
      key: "action",
      width: "100px",
      align: "center",
      render: (_: any, record: any) => (
        <div className={cls.editButton}>
          <Button onClick={() => setIsModalVisible(record)} type="text" shape="circle" icon={<EditOutlined />} />
          <Button
            type="text"
            onClick={async () => {
              await request.post("/administrator-bank-information/delete", { id: record.id });
              getInfo();
            }}
            shape="circle"
            icon={<DeleteOutlined />}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Банк" />
      <Button
        type="primary"
        style={{ marginLeft: "auto", display: "flex", marginBottom: 10 }}
        onClick={() => setIsModalVisible(true)}
      >
        Создать
      </Button>
      <ModalAdd isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} getAll={getInfo} />

      <Table loading={loading} rowKey="id" pagination={false} columns={columns} dataSource={info.data} />

      <Pagination
        count={info.pagination.count}
        limit={info.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </div>
  );
};
