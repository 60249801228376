/* eslint-disable jsx-a11y/img-redundant-alt */
import { Alert, Avatar, Button, Image, notification, PageHeader, Space } from "antd";
import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Spinner } from "../../components/spinner";
import { isPlatform } from "../../utils/isPlatform";
import { request } from "../../utils/request";
import cls from "./style.module.scss";

export const ListPhoto: FC = () => {
  const [searchParams] = useSearchParams();

  const [data, setData] = useState<any>({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [imageModalIsVisible, setImageModalIsVisible] = useState("");
  const navigate = useNavigate();

  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;

  const getData = async () => {
    const response = await request.post("/administrator-information/listPhoto", {
      offset: 500 * (page - 1),
    });
    setData(response.data);
    setLoading(false);
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (loading) {
    return <Spinner />;
  }

  const analysis = async (image: string, adult: boolean): Promise<void> => {
    await axios
      .post("http://localhost:3300/image/train", {
        imageUrl: [image],
        bool: [adult],
      })
      .then(() => {
        notification.success({ message: "Всё хорошо", description: "Прокатило!" });
      })
      .catch(() => {
        notification.error({ message: "Ошибка", description: "Не прокатило..." });
      });
  };

  return (
    <div>
      <PageHeader ghost={false} title="Приватные фотографии" />
      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{
            visible: !!imageModalIsVisible,
            onVisibleChange: (visible) => setImageModalIsVisible(visible ? "1" : ""),
          }}
        >
          <Image src={imageModalIsVisible} />
        </Image.PreviewGroup>
      </div>
      {data.data.length ? (
        <Space style={{ width: "100%" }} direction="vertical">
          {data.data.map((item: any) => (
            <div key={item.id} style={{ overflow: "auto", marginBottom: 16 }}>
              <Space className={cls.card} key={item.id} direction="horizontal">
                <Space
                  onClick={() => navigate(`/profile/${item.profile.id}`)}
                  style={{ minWidth: 140, cursor: "pointer" }}
                >
                  <Avatar
                    src={
                      item.profile?.avatar
                        ? `${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${item.profile.avatar.image}`
                        : "https://amorus.ru/assets/images/stub_male.png"
                    }
                  />
                  <span style={{ minWidth: 140 }}>{item.profile.name}</span>
                </Space>
                {!!item.value.length && (
                  <div style={{ display: "flex" }}>
                    {item.value.map((url: any, index: number) => {
                      const urlImage = `${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${url}`;

                      return (
                        <span style={{ padding: 10, border: "1px solid #ccc", marginRight: 10 }} key={index}>
                          <div style={{ display: "flex", gap: 10, marginBottom: 10 }}>
                            <Button
                              size="small"
                              onClick={() =>
                                axios
                                  .post("http://localhost:3300/image", { imageUrl: urlImage, bool: true })
                                  .then((e) => alert(`${e.data.isAdultContent} ${e.data.resultNumber}`))
                              }
                            >
                              Результат
                            </Button>
                            <Button size="small" onClick={() => analysis(urlImage, true)} danger>
                              Приватное
                            </Button>
                            <Button size="small" onClick={() => analysis(urlImage, false)} type="primary">
                              Нормальное
                            </Button>
                          </div>
                          <img
                            loading="lazy"
                            onClick={() => setImageModalIsVisible(urlImage)}
                            src={urlImage}
                            style={{
                              width: 260,
                              height: "auto",
                            }}
                            alt="picture"
                          />
                        </span>
                      );
                    })}
                  </div>
                )}
                {/* <span>{textFormate(item.message, (url) => setImageModalIsVisible(url ?? ''))}</span> */}
              </Space>
            </div>
          ))}
        </Space>
      ) : (
        <Alert message="ну ху знает" type="info" />
      )}
    </div>
  );
};
