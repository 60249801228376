import { DeleteOutlined } from "@ant-design/icons";
import { Button, Form, Input, notification, PageHeader, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment/moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { request } from "../../utils/request";
import cls from "./style.module.scss";

export const Ads: FC = () => {
  const [query] = useState("");
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;

  const [data, setData] = useState({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });

  const getData = async () => {
    const response = await request.post("/notice/administrator/all", {
      offset: 50 * (page - 1),
      query,
    });
    setData(response.data);
    setLoading(false);
  };

  const remove = async (id: number) => {
    setLoading(true);

    await request.delete("/notice/administrator/remove", {
      data: { id },
    });

    await getData();
  };

  const edit = async ({ id, message, published }: { message?: string; id: number; published?: boolean }) => {
    await request.put("/notice/administrator/edit", {
      id,
      message,
      published,
    });

    await getData();

    notification.success({
      message: "Успешно сохранено",
      description: "Давайте радоваться",
    });
  };

  // const search = (query: string) => {
  //   setQuery(query);
  //   setSearchParams({ page: "1" });
  // };

  const startMailing = async (adId: number) => {
    await request.post("/notice/administrator/mailing", {
      adId,
    });

    notification.success({
      message: "Ура! Сработало",
      description: "Рассылка запущена",
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query]);

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      width: 600,
      title: "Текст",
      dataIndex: "message",
      key: "message",
      render: (_, { message, id }) => (
        <Fragment>
          <Form
            onFinish={edit}
            initialValues={{
              id,
              message,
            }}
          >
            <Form.Item hidden name="id" rules={[{ required: true }]}>
              <Input hidden />
            </Form.Item>
            <Form.Item style={{ marginBottom: 12 }} name="message" rules={[{ required: true }]}>
              <Input.TextArea className={cls.textArea} autoSize />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form>
        </Fragment>
      ),
    },
    {
      title: "Отклики",
      width: 100,
      dataIndex: "id",
      key: "responses",
      render: (_, { responses }) => {
        return responses.map((item: any) => (
          <a target="_blank" key={item.profileId} href={`/profile/${item.profileId}`} rel="noreferrer">
            {item.profileId}{" "}
          </a>
        ));
      },
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "id",
      render: (_, data) => moment(data.createdAt).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "Опубликовано",
      dataIndex: "published",
      key: "published",
      render: (_, { id, published }) => (
        <Fragment>
          {published ? (
            <Button onClick={() => edit({ id, published: false })} size="small" type="primary">
              Да
            </Button>
          ) : (
            <Button onClick={() => edit({ id, published: true })} size="small" danger={true}>
              Нет
            </Button>
          )}
        </Fragment>
      ),
    },
    {
      title: "Профиль",
      dataIndex: "profileId",
      key: "profileId",
      render: (_, { profileId }) => {
        return (
          <a href={`/profile/${profileId}`} rel="noreferrer">
            {profileId}
          </a>
        );
      },
    },
    {
      title: "Управление",
      key: "action",
      render: (_, { id }) => (
        <Space>
          <Button onClick={() => startMailing(id)} size="small" type="primary">
            Запустить рассылку
          </Button>
          <Popconfirm placement="leftTop" title="Удалить?" onConfirm={() => remove(id)} okText="Да" cancelText="Нет">
            <Button size="small" danger={true} icon={<DeleteOutlined />}>
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Объявления" />

      {/*<Input.Search*/}
      {/*  size="large"*/}
      {/*  style={{ width: "100%", marginBottom: 16 }}*/}
      {/*  placeholder="Поиск"*/}
      {/*  onSearch={search}*/}
      {/*/>*/}

      <Table
        loading={loading}
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={data.data}
        scroll={{ x: 300 }}
      />

      <Pagination
        count={data.pagination.count}
        limit={data.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />

      <style>{`table td { vertical-align: top; }`}</style>
    </div>
  );
};
