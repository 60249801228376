import "./index.scss";

import {
  AlertOutlined,
  CheckSquareOutlined,
  DashboardOutlined,
  DingtalkOutlined,
  DollarOutlined,
  ExceptionOutlined,
  FileImageOutlined,
  FileWordOutlined,
  LineChartOutlined,
  MenuOutlined,
  MessageOutlined,
  ToTopOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Layout, Menu, Switch } from "antd";
import type { ItemType } from "antd/lib/menu/hooks/useItems";
import React, { FC, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useChangeType } from "../../utils/useChangeType";

const { Header, Content, Footer, Sider } = Layout;

const routes: ItemType[] = [
  {
    key: "/photo-moderation",
    label: "Фотографии",
    icon: <FileImageOutlined />,
  },
  {
    key: "/ads",
    label: "Объявления",
    icon: <ExceptionOutlined />,
  },
  {
    key: "/complaints",
    label: "Жалобы",
    icon: <AlertOutlined />,
  },
  {
    key: "/confirmations",
    label: "Подтверждения",
    icon: <CheckSquareOutlined />,
  },
  {
    type: "divider",
  },
  {
    key: "/users",
    label: "Пользователи",
    icon: <UserOutlined />,
  },
  {
    key: "/profiles",
    label: "Профиля",
    icon: <UserOutlined />,
  },
  {
    type: "divider",
  },
  {
    key: "/",
    label: "Статистика",
    icon: <DashboardOutlined />,
  },
  {
    key: "/charts",
    label: "Графики",
    icon: <DashboardOutlined />,
  },
  {
    type: "divider",
  },
  {
    key: "/transactions",
    label: "Транзакции",
    icon: <DollarOutlined />,
  },
  {
    key: "/bank",
    label: "Банк",
    icon: <DollarOutlined />,
  },
  {
    key: "/expenses",
    label: "Расходы",
    icon: <DollarOutlined />,
  },
  {
    type: "divider",
  },
  {
    key: "/messages",
    label: "Сообщения",
    icon: <MessageOutlined />,
  },
  {
    key: "/list-photo",
    label: "Приватные фотографии",
    icon: <MessageOutlined />,
  },
  {
    key: "/promotions",
    label: "Продвижение",
    icon: <ToTopOutlined />,
  },
  {
    key: "/pages",
    label: "Страницы",
    icon: <FileWordOutlined />,
  },
  {
    type: "divider",
  },
  {
    key: "/ratings",
    label: "Рейтинги",
    icon: <LineChartOutlined />,
  },
];

export const Wrapper: FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [typeAdmin, setTypeAdmin] = useChangeType(); //lovers

  const [open, setOpen] = useState(false);
  const selected = routes.filter((item) => location.pathname === item?.key).map((item) => item?.key);

  return (
    <Layout>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          background: typeAdmin === "amorus" ? "" : "#006e75",
        }}
        className="slider"
      >
        <Menu
          onSelect={({ key }) => navigate(key)}
          items={routes}
          selectedKeys={selected as any}
          theme="dark"
          style={{
            background: typeAdmin === "amorus" ? "" : "#006e75",
          }}
          mode="inline"
        />
      </Sider>
      <Drawer placement="left" closable={false} onClose={() => setOpen(false)} open={open} bodyStyle={{ padding: 0 }}>
        <Menu
          onSelect={({ key }) => {
            navigate(key);
            setOpen(false);
          }}
          items={routes}
          selectedKeys={selected as any}
          mode="inline"
        />
      </Drawer>
      <Layout className="site-layout">
        <Header
          className="site-layout-background"
          style={{ padding: 0, background: typeAdmin === "amorus" ? "" : "#006e75" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
            }}
          >
            <Button type="primary" onClick={() => setOpen(true)} className="menu-button">
              <MenuOutlined />
            </Button>
            <Link to="/" className="logo">
              <DingtalkOutlined /> {typeAdmin === "amorus" ? "AMORUS" : "LOVERS"}
            </Link>
            <Switch
              checked={typeAdmin !== "lovers"}
              onChange={(e) => setTypeAdmin(e ? "amorus" : "lovers")}
              checkedChildren="amorus"
              unCheckedChildren="lovers"
              style={{ marginLeft: "auto" }}
            />
          </div>
        </Header>
        <Content style={{ margin: "16px 16px 0", overflow: "initial" }}>
          <div className="site-layout-background-2">{children}</div>
        </Content>
        <Footer style={{ textAlign: "right" }}>AMORUS ©2022</Footer>
      </Layout>
    </Layout>
  );
};
