import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, PageHeader, Popconfirm, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { FC, Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { IUser } from "../../interfaces/user";
import { request } from "../../utils/request";

export const Users: FC = () => {
  const [query, setQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;
  const [loading, setLoading] = useState(true);
  const [banDay, setBanDay] = useState("");

  const [users, setUsers] = useState({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });

  const getUsers = async () => {
    const response = await request.post("/users/all", {
      offset: 50 * (page - 1),
      query,
    });
    setUsers(response.data);
    setLoading(false);
  };

  const ban = async (user: IUser) => {
    setLoading(true);

    const banned = !user.isBanned;

    await request.post("/users/ban", {
      id: user.id,
      banned,
      days: banned && banDay ? Number(banDay) : undefined,
    });

    setBanDay("");
    await getUsers();
  };

  const subscribeToggle = async (email: string, subscribed: boolean) => {
    setLoading(true);

    await request.post("/mail/subscribe-administrator", {
      email,
      subscribed: !subscribed,
    });

    await getUsers();
  };

  const emailConfirmedToggle = async (userId: number, confirmed: boolean) => {
    setLoading(true);

    await request.post("/users/email-change-confirmed", {
      userId,
      confirmed,
    });

    await getUsers();
  };

  const remove = async (id: number) => {
    setLoading(true);

    await request.post("/users/delete", {
      id,
    });

    await getUsers();
  };

  const search = (query: string) => {
    setQuery(query);
    setSearchParams({ page: "1" });
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query]);

  const columns: ColumnsType<IUser> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "id",
      render: (_, { createdAt }) => moment(createdAt).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      render: (_, { roles }) =>
        roles.map(({ id, value, description }) => (
          <Tag color={value === "user" ? "default" : value === "delete" ? "red" : "green"} key={id}>
            {description}
          </Tag>
        )),
    },
    {
      title: "Статус",
      key: "banned",
      dataIndex: "banned",
      render: (_, { banned }) => <Tag color={banned ? "red" : "green"}>{banned ? "Заблокирован" : "Активен"}</Tag>,
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "E-mail подтвержден",
      dataIndex: "email_confirmed",
      key: "email_confirmed",
      render: (_, { id, email_confirmed }) => (
        <Button
          onClick={() => emailConfirmedToggle(id, !email_confirmed)}
          size="small"
          type="primary"
          danger={!email_confirmed}
        >
          {email_confirmed ? "Да" : "Нет"}
        </Button>
      ),
    },
    {
      title: "Подписка",
      dataIndex: "subscribe",
      key: "subscribe",
      render: (_, { email, subscribe }) => (
        <Button
          loading={loading}
          onClick={() => subscribeToggle(email, !!subscribe?.subscribed)}
          size="small"
          type="primary"
          danger={!subscribe?.subscribed}
        >
          {subscribe?.subscribed ? "Да" : "Нет"}
        </Button>
      ),
    },
    {
      title: "Бан",
      key: "banned",
      render: (_, user) => {
        return (
          <Fragment>
            {user.isBanned ? (
              <Space direction="vertical">
                {user.banned_end_date ? <span>{moment(user.banned_end_date).format("DD.MM.YYYY HH:mm")}</span> : null}
                <Button onClick={() => ban(user)} size="small" danger={!user.isBanned}>
                  Активировать
                </Button>
              </Space>
            ) : (
              <Space direction="vertical">
                <Input
                  value={banDay}
                  onChange={({ target: { value } }) => setBanDay(value)}
                  size="small"
                  placeholder="Дней"
                  style={{ width: 105 }}
                />
                <Popconfirm
                  placement="leftTop"
                  title="Уверен?"
                  onConfirm={() => ban(user)}
                  okText="Да"
                  cancelText="Нет"
                >
                  <Button size="small" danger={!user.isBanned}>
                    Заблокировать
                  </Button>
                </Popconfirm>
              </Space>
            )}
          </Fragment>
        );
      },
    },
    {
      title: "Управление",
      key: "action",
      render: (_, user) => (
        <Space>
          <Popconfirm
            placement="leftTop"
            title="Удалить пользователя?"
            onConfirm={() => remove(user.id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button size="small" danger={true} icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Пользователи" />

      <Input.Search size="large" style={{ width: "100%", marginBottom: 16 }} placeholder="Поиск" onSearch={search} />

      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 300 }}
        pagination={false}
        columns={columns}
        dataSource={users.data}
      />

      <Pagination
        count={users.pagination.count}
        limit={users.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </div>
  );
};
