import { notification } from "antd";
import axios from "axios";
import cookie from "cookiejs";

import { isPlatform } from "./isPlatform";

const token = cookie.get("token");

export const request = axios.create({
  baseURL:
    isPlatform(process.env.REACT_APP_NOT_SECRET_API_URL, process.env.REACT_APP_NOT_SECRET_API_URL_LOVERS) ||
    "https://api.amorus.ru/",
  headers: {
    Authorization: `Bearer ${token}`,
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
  responseType: "json",
  transformResponse: (data) => {
    const parsed = JSON.parse(data);

    if (parsed.error && parsed.messages) {
      parsed.messages.forEach((message: string) => {
        notification.error({
          message: "Ошибка",
          description: message,
        });
      });
    }

    return parsed;
  },
});
