import { Button, Form, Input, notification, PageHeader, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { landings } from "../../contastants/landings";
import { request } from "../../utils/request";

export interface ICity {
  id: number;
  name: string;
  country: {
    id: number;
    name: string;
  };
}

export const PagesManage: FC = () => {
  const [page, setPage] = useState<object | null>(null);
  const [cities, setCities] = useState<ICity[]>([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [form] = useForm();

  const getPage = async (id: number) => {
    setLoading(true);
    const response = await request.get(`/page/${id}`);
    setPage(response.data);
    setLoading(false);
  };

  const getCities = async (id?: number) => {
    const response = await request.get("/geo/cities", {
      params: {
        countryId: id ?? 1,
      },
    });
    setCities(response.data);
  };

  const save = async (values: any) => {
    values.body = values.body || null;

    try {
      if (id) {
        await request.put(`/page`, { ...values, id: Number(id) });
        await getPage(Number(id));
      } else {
        const result = await request.post(`/page`, values);
        navigate(`/pages/manage/${result.data.id}`);
      }

      notification.success({ message: "Сохранение прошло успешно" });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (id) {
      getPage(Number(id));
    }
    getCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onFinish = (values: object) => {
    save(values);
  };

  return (
    <div>
      <PageHeader ghost={false} title="Редактирование/Добавление страницы" />

      {loading ? (
        <Spin />
      ) : (
        <Form initialValues={page || {}} name="form_item_path" layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item name="heading" label="Заголовок" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="alias" label="Alias" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="country" label="Страна" initialValue={1} rules={[{ required: true }]}>
            <Select
              showSearch
              onChange={(value) => {
                getCities(value);
                form.setFieldValue("cityId", "");
              }}
              options={[
                {
                  label: "Россия",
                  value: 1,
                },
                {
                  label: "РБ",
                  value: 2,
                },
              ]}
            />
          </Form.Item>
          <Form.Item name="cityId" label="Город">
            <Select
              showSearch
              filterOption={(text, value) => {
                return String(value?.label || "")
                  .toLowerCase()
                  .includes(text.toLowerCase());
              }}
              options={cities.map((el) => ({ value: el.id, label: el.name }))}
            />
          </Form.Item>
          <Form.Item name="title" label="SEO: Title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="SEO: Description" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="body" label="Содержимое">
            <Input.TextArea rows={20} />
          </Form.Item>
          <Form.Item name="domain" label="Сайт">
            <Select
              options={[
                { value: "amorus", label: "amorus.ru" },
                { value: "lovelaz", label: "lovelaz.ru" },
                { value: "soderzhanka", label: "soderzhanka.ru" },
              ]}
            />
          </Form.Item>
          <Form.Item name="template" label="Шаблон">
            <Select options={Object.entries(landings).map(([value, label]) => ({ value, label }))} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" key="add" loading={loading} type="primary" size="middle">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
