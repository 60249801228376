import { Empty, PageHeader, Select } from "antd";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { request } from "../../utils/request";
import cls from "./style.module.scss";

interface ITransactions {
  amount: number;
  updatedAt: string;
  payment_system?: string;
}

interface IStatistics {
  users: number;
  transactions: ITransactions[];
  profiles: {
    all: number;
    male: number;
    female: number;
  };
}

interface IMoney {
  Cur_OfficialRate: number;
  byn: number;
  Cur_Abbreviation: string;
}

const dateObj: any = {
  1: "2024",
  2: "2023",
  3: "Все",
};

export const Charts: FC = () => {
  const [chart1, setChart1] = useState(1);
  const [chart2, setChart2] = useState(1);

  // const navigate = useNavigate()
  const [statistics, setStatistics] = useState<IStatistics>({
    transactions: [],
    users: 0,
    profiles: {
      all: 0,
      male: 0,
      female: 0,
    },
  });

  const [information, setInformation] = useState([]);
  const [money, setMoney] = useState<IMoney[]>([]);

  useEffect(() => {
    request.get("https://www.nbrb.by/api/exrates/rates?periodicity=0").then((response) => {
      let arr = [
        { country: "usa", mony: "USD" },
        { country: "eur", mony: "EUR" },
        { country: "ru", mony: "RUB" },
      ].map((el) => response.data.find((mo: any) => mo.Cur_Abbreviation === el.mony));
      arr = arr.map((el) => ({
        ...el,
        byn: arr[arr.length - 1].Cur_OfficialRate / 100,
      }));
      arr[arr.length - 1].Cur_Abbreviation = "BYN";

      setMoney(arr);
    });
  }, []);

  useEffect(() => {
    request.get("/statistics").then((response) => {
      setStatistics(response.data);
    });
    request
      .post("/administrator-information/list", {
        offset: 0,
        query: "",
        limit: 99999,
      })
      .then((response) => {
        setInformation(response.data.data);
      });
  }, []);

  const refEl = useRef<HTMLDivElement>(null);

  const transactionsAmount = statistics.transactions.reduce(
    (previousValue: { subject: string; fullMark: number }[], currentValue) => {
      const findEl = previousValue.find((el) =>
        currentValue.payment_system ? el.subject === currentValue.payment_system : el.subject === "any",
      );
      if (findEl && currentValue.amount) {
        const copyFindEl = JSON.parse(JSON.stringify(findEl));
        copyFindEl.fullMark = copyFindEl.fullMark + currentValue.amount;
        previousValue.splice(previousValue.indexOf(findEl), 1, copyFindEl);
      } else {
        if (currentValue.amount) {
          previousValue.push({
            subject: currentValue.payment_system ? currentValue.payment_system : "any",
            fullMark: currentValue.amount,
          });
        }
      }
      return previousValue;
    },
    [],
  );

  const transactions = statistics.transactions
    .sort(
      (a: any, b: any) =>
        (((new Date(a.date ?? (a.updatedAt as number)) as any) - (new Date(b.date ?? b.updatedAt) as any)) as any) ?? 0,
    )
    .reduce((previousValue: ITransactions[], currentValue) => {
      const copyArr = JSON.parse(JSON.stringify(previousValue));
      if (copyArr.length) {
        const el = copyArr[copyArr.length - 1];
        if (moment(el.updatedAt).format("YY.MM.DD") === moment(currentValue.updatedAt).format("YY.MM.DD")) {
          el.amount = el.amount + currentValue.amount;
          return copyArr;
        } else {
          return copyArr.concat(currentValue);
        }
      }
      return previousValue.concat(currentValue);
    }, []);

  const transactionsResp = information
    .sort(
      (a: any, b: any) =>
        (((new Date(a.date ?? (a.updatedAt as number)) as any) - (new Date(b.date ?? b.updatedAt) as any)) as any) ?? 0,
    )
    .reduce((previousValue: any[], currentValue: any) => {
      const copyArr = JSON.parse(JSON.stringify(previousValue));
      if (copyArr.length) {
        const el = copyArr[copyArr.length - 1];
        if (moment(el.updatedAt).format("YY.MM.DD") === moment(currentValue.updatedAt).format("YY.MM.DD")) {
          el.amountOfCosts = el.amountOfCosts + currentValue.amountOfCosts;
          return copyArr;
        } else {
          return copyArr.concat(currentValue);
        }
      }
      return previousValue.concat(currentValue);
    }, []);

  const transactionsRespAll = transactions
    .concat(transactionsResp)
    .sort(
      (a: any, b: any) =>
        (((new Date(a.date ?? (a.updatedAt as number)) as any) - (new Date(b.date ?? b.updatedAt) as any)) as any) ?? 0,
    )
    .reduce((previousValue: any[], currentValue: any) => {
      const copyArr = JSON.parse(JSON.stringify(previousValue));
      if (copyArr.length) {
        const el = copyArr[copyArr.length - 1];
        if (
          moment(el.date ?? el.updatedAt).format("YY.MM.DD") ===
          moment(currentValue.date ?? currentValue.updatedAt).format("YY.MM.DD")
        ) {
          el.amountOfCosts = currentValue.amountOfCosts ?? el.amountOfCosts;
          el.amount = currentValue.amount ?? el.amount;
          return copyArr;
        } else {
          return copyArr.concat(currentValue);
        }
      }
      return previousValue.concat(currentValue);
    }, []);

  const transactionsMM = statistics.transactions.reduce((previousValue: ITransactions[], currentValue) => {
    const copyArr = JSON.parse(JSON.stringify(previousValue));
    if (copyArr.length) {
      const el = copyArr[copyArr.length - 1];
      if (moment(el.updatedAt).isSame(currentValue.updatedAt, "months")) {
        el.amount = el.amount + currentValue.amount;
        return copyArr;
      } else {
        return copyArr.concat(currentValue);
      }
    }
    return previousValue.concat(currentValue);
  }, []);

  const transactionsMMResp = information
    .sort((a: any, b: any) => (((new Date(a.date as number) as any) - (new Date(b.date) as any)) as any) ?? 0)
    .reduce((previousValue: any[], currentValue: any) => {
      const copyArr = JSON.parse(JSON.stringify(previousValue));
      if (copyArr.length) {
        const el = copyArr[copyArr.length - 1];
        if (moment(el.date).isSame(currentValue.date, "months")) {
          el.amountOfCosts = el.amountOfCosts + currentValue.amountOfCosts;
          return copyArr;
        } else {
          return copyArr.concat(currentValue);
        }
      }
      return previousValue.concat(currentValue);
    }, []);

  const transactionsRespMMAll = transactionsMMResp
    .concat(transactionsMM)
    .sort(
      (a: any, b: any) =>
        (((new Date(a.date ?? (a.updatedAt as number)) as any) - (new Date(b.date ?? b.updatedAt) as any)) as any) ?? 0,
    )
    .reduce((previousValue: any[], currentValue: any) => {
      const copyArr = JSON.parse(JSON.stringify(previousValue));
      if (copyArr.length) {
        const el = copyArr[copyArr.length - 1];
        if (
          moment(el.date ?? el.updatedAt).format("YY.MM") ===
          moment(currentValue.date ?? currentValue.updatedAt).format("YY.MM")
        ) {
          el.amountOfCosts = currentValue.amountOfCosts ?? el.amountOfCosts;
          el.amount = currentValue.amount ?? el.amount;
          return copyArr;
        } else {
          return copyArr.concat(currentValue);
        }
      }
      return previousValue.concat(currentValue);
    }, []);

  const renderMony = (sum?: any) =>
    sum &&
    money
      .map((el, idx) =>
        (idx === money.length - 1 ? sum * el.byn : (sum * el.byn) / el.Cur_OfficialRate).toLocaleString("ru-RU", {
          style: "currency",
          currency: el.Cur_Abbreviation,
        }),
      )
      .join(", ");

  const CustomTooltip: FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={cls.customTooltip}>
          <h2>{label}</h2>
          {!!payload[0].value && (
            <span
              style={{ color: payload[0].color }}
              className={cls.customTooltip__label}
            >{`${payload[0].name} : ${payload[0].value.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
            })} (${renderMony(payload[0].value)})`}</span>
          )}
          {!!payload[1]?.value && (
            <span
              style={{ color: payload[1].color }}
              className={cls.customTooltip__label}
            >{`${payload[1].name} : ${payload[1].value.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
            })} (${renderMony(payload[1].value)})`}</span>
          )}
          {!!(payload[1]?.value && payload[0]?.value) && (
            <span style={{ color: "#a182ca" }} className={cls.customTooltip__label}>{`Доход : ${(
              payload[1].value - payload[0].value
            ).toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
            })} (${renderMony(payload[1].value - payload[0].value)})`}</span>
          )}
        </div>
      );
    }

    return null;
  };

  const transactionsCurrencyMonth = statistics.transactions
    .sort(
      (a: any, b: any) =>
        (((new Date(a.date ?? (a.updatedAt as number)) as any) - (new Date(b.date ?? b.updatedAt) as any)) as any) ?? 0,
    )
    .filter(
      (el) =>
        String(new Date(el.updatedAt).getFullYear()) === String(new Date().getFullYear()) &&
        String(new Date(el.updatedAt).getMonth()) === String(new Date().getMonth()),
    )
    .reduce((previousValue: { subject: string; fullMark: number }[], currentValue) => {
      const findEl = previousValue.find((el) =>
        currentValue.payment_system ? el.subject === currentValue.payment_system : el.subject === "any",
      );
      if (findEl) {
        const copyFindEl = JSON.parse(JSON.stringify(findEl));
        copyFindEl.fullMark = copyFindEl.fullMark + currentValue.amount;
        previousValue.splice(previousValue.indexOf(findEl), 1, copyFindEl);
      } else {
        if (currentValue.amount) {
          previousValue.push({
            subject: currentValue.payment_system ? currentValue.payment_system : "any",
            fullMark: currentValue.amount,
          });
        }
      }
      return previousValue;
    }, []);

  return (
    <div>
      <PageHeader ghost={false} title="Графики" />
      <div className={cls.wrapperUsers}>
        <h2>Платёжки</h2>
        <div ref={refEl} className={cls.wrapperTable2}>
          <div style={{ height: 270 }}>
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart cx="50%" cy="50%" outerRadius="80%" data={transactionsAmount}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <Radar name="Mike" dataKey="fullMark" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className={cls.wrapperUsers}>
        <h2>Текущий месяц</h2>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {transactionsCurrencyMonth.map((el) => (
            <span key={el.subject}>{`${el.subject} : ${el.fullMark.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
            })} (${renderMony(el.fullMark)})`}</span>
          ))}
        </div>
        {transactionsRespAll.length ? (
          <div ref={refEl} className={cls.wrapperTable}>
            <div className={cls.wrapperChart}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={transactionsRespAll.filter(
                    (el) =>
                      String(new Date(el.date ?? el.updatedAt).getFullYear()) === String(new Date().getFullYear()) &&
                      String(new Date(el.date ?? el.updatedAt).getMonth()) === String(new Date().getMonth()),
                  )}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <YAxis unit=" ₽" dataKey={(data) => Number(data.amount)} name="Деньги" />
                  <XAxis dataKey={(data) => moment(data.date ?? data.updatedAt).format("DD.MM.YY")} />
                  <Tooltip filterNull={false} content={<CustomTooltip />} />
                  <Legend />
                  <Bar dataKey="amount" name="Денюшки" fill="#82ca9d" stackId="1" minPointSize={0} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className={cls.wrapperEmpty}>
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 100,
              }}
              description={<span>Наполнения отсутствуют</span>}
            ></Empty>
          </div>
        )}
      </div>
      <div className={cls.wrapperUsers}>
        <Select
          value={chart1}
          onChange={setChart1}
          options={Object.keys(dateObj).map((key) => ({
            label: dateObj[key],
            value: Number(key),
          }))}
        />
        {transactionsRespAll.length ? (
          <div ref={refEl} className={cls.wrapperTable}>
            <div className={cls.wrapperChart}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={
                    [1, 2].includes(chart1)
                      ? transactionsRespAll.filter(
                          (el) => String(new Date(el.date ?? el.updatedAt).getFullYear()) === dateObj[chart1],
                        )
                      : transactionsRespAll
                  }
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <YAxis unit=" ₽" dataKey={(data) => Number(data.amount)} name="Деньги" />
                  <XAxis dataKey={(data) => moment(data.date ?? data.updatedAt).format("DD.MM.YY")} />
                  <Tooltip filterNull={false} content={<CustomTooltip />} />
                  <Legend />
                  <Bar dataKey="amountOfCosts" name="Расходы" fill="#ca8282" stackId="1" minPointSize={0} />
                  <Bar dataKey="amount" name="Денюшки" fill="#82ca9d" stackId="1" minPointSize={0} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className={cls.wrapperEmpty}>
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 100,
              }}
              description={<span>Наполнения отсутствуют</span>}
            ></Empty>
          </div>
        )}
      </div>

      <h2>По месяцам</h2>
      <div className={cls.wrapperUsers}>
        <Select
          value={chart2}
          onChange={setChart2}
          options={Object.keys(dateObj).map((key) => ({
            label: dateObj[key],
            value: Number(key),
          }))}
        />
        {transactionsRespMMAll.length ? (
          <div ref={refEl} className={cls.wrapperTable}>
            <div className={cls.wrapperChart}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={
                    [1, 2].includes(chart2)
                      ? transactionsRespMMAll.filter(
                          (el: any) => String(new Date(el.date ?? el.updatedAt).getFullYear()) === dateObj[chart2],
                        )
                      : transactionsRespMMAll
                  }
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <YAxis unit=" ₽" dataKey={(data) => Number(data.amount)} name="Деньги" />
                  <XAxis dataKey={(data) => moment(data.date ?? data.updatedAt).format("MM.YYYY")} />
                  <Tooltip filterNull={false} content={<CustomTooltip />} />
                  <Legend />

                  <Bar dataKey="amountOfCosts" name="Расходы" fill="#ca8282" stackId="1" minPointSize={0} />
                  <Bar dataKey="amount" name="Денюшки" fill="#82ca9d" stackId="2" minPointSize={0} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className={cls.wrapperEmpty}>
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 100,
              }}
              description={<span>Наполнения отсутствуют</span>}
            ></Empty>
          </div>
        )}
      </div>
    </div>
  );
};
