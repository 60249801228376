import { Card, Col, PageHeader, Row, Statistic, Typography } from "antd";
import moment from "moment";
import { FC, Fragment, useEffect, useState } from "react";

import { request } from "../../utils/request";
interface ITransactions {
  amount: number;
  updatedAt: string;
}

interface IMoney {
  Cur_OfficialRate: number;
  byn: number;
  Cur_Abbreviation: string;
}

interface IStatistics {
  users: number;
  transactions: ITransactions[];
  profiles: {
    all: number;
    male: number;
    female: number;
  };
  online: {
    male: number;
    female: number;
  };
}

export const Dashboard: FC = () => {
  const [loading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState<IStatistics>({
    transactions: [],
    users: 0,
    profiles: {
      all: 0,
      male: 0,
      female: 0,
    },
    online: {
      male: 0,
      female: 0,
    },
  });

  const [information, setInformation] = useState<number>(0);
  const [informationBank, setInformationBank] = useState<number>(0);
  const [money, setMoney] = useState<IMoney[]>([]);

  const getInformation = (): void => {
    request.get("/administrator-information").then((response) => {
      if (response.data.administration) {
        setInformation(
          response.data.administration.reduce((a: number, b: { amountOfCosts: number }) => a + b.amountOfCosts, 0),
        );
      }
      setLoading(false);
    });
  };

  const getInformationBank = (): void => {
    request.get("/administrator-bank-information").then((response) => {
      if (response.data.administration) {
        setInformationBank(
          response.data.administration.reduce((a: number, b: { amountOfCosts: number }) => a + b.amountOfCosts, 0),
        );
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    request.get("/statistics").then((response) => {
      setStatistics(response.data);
      setLoading(false);
    });
    getInformation();
    getInformationBank();
    request.get("https://www.nbrb.by/api/exrates/rates?periodicity=0").then((response) => {
      let arr = [
        { country: "usa", mony: "USD" },
        { country: "eur", mony: "EUR" },
        { country: "ru", mony: "RUB" },
      ].map((el) => response.data.find((mo: any) => mo.Cur_Abbreviation === el.mony));
      arr = arr.map((el) => ({ ...el, byn: arr[arr.length - 1].Cur_OfficialRate / 100 }));
      arr[arr.length - 1].Cur_Abbreviation = "BYN";

      setMoney(arr);
    });
  }, []);

  const transactions = statistics.transactions.reduce((previousValue: ITransactions[], currentValue) => {
    const copyArr = JSON.parse(JSON.stringify(previousValue));
    if (copyArr.length) {
      if (
        moment(copyArr[copyArr.length - 1].updatedAt).format("YY.MM.DD") ===
        moment(currentValue.updatedAt).format("YY.MM.DD")
      ) {
        copyArr[copyArr.length - 1].amount = copyArr[copyArr.length - 1].amount + currentValue.amount;
        return copyArr;
      } else {
        return copyArr.concat(currentValue);
      }
    }
    return previousValue.concat(currentValue);
  }, []);

  const transactionsMax =
    !!transactions.length &&
    transactions.reduce((previousValue: ITransactions, currentValue) => {
      if (previousValue) {
        if (previousValue.amount < currentValue.amount) {
          return currentValue;
        }
        return previousValue;
      }
      return currentValue;
    });

  const transactionsSum = statistics.transactions.reduce((a, b) => a + b.amount, 0);

  const renderMony = (sum?: any) =>
    sum &&
    money.map((el, idx) => (
      <p key={el.Cur_Abbreviation}>
        <span>
          {(idx === money.length - 1 ? sum * el.byn : (sum * el.byn) / el.Cur_OfficialRate).toLocaleString("ru-RU", {
            style: "currency",
            currency: el.Cur_Abbreviation,
          })}
        </span>
      </p>
    ));

  return (
    <Fragment>
      <PageHeader ghost={false} title="Статистика" />
      <Row gutter={16} style={{ rowGap: 16 }}>
        <Col span={24} xxl={6} lg={12}>
          <Card>
            <Statistic
              loading={!transactions || loading}
              title="Сумма за сегодня"
              value={
                transactions.length
                  ? `${transactions[transactions.length - 1].amount.toLocaleString("ru-RU", {
                      style: "currency",
                      currency: "RUB",
                    })} - ${moment(transactions[transactions.length - 1].updatedAt).format("DD.MM.YYYY")} г.`
                  : ""
              }
            />
            {renderMony(transactions[transactions.length - 1]?.amount)}
          </Card>
        </Col>
        <Col span={24} xxl={6} lg={12}>
          <Card>
            <Statistic
              loading={loading}
              title="Депы / Сумма"
              value={`${statistics.transactions.length} / ${transactionsSum.toLocaleString("ru-RU", {
                style: "currency",
                currency: "RUB",
              })}`}
            />
            {renderMony(transactionsSum)}
          </Card>
        </Col>
        <Col span={24} xxl={6} lg={12}>
          <Card>
            <Statistic
              loading={!transactionsMax || loading}
              title="Max сумма"
              value={
                transactionsMax
                  ? `${transactionsMax.amount.toLocaleString("ru-RU", {
                      style: "currency",
                      currency: "RUB",
                    })} - ${moment(transactionsMax.updatedAt).format("DD.MM.YYYY")} г.`
                  : ""
              }
            />
            {renderMony(transactionsMax ? transactionsMax.amount : 0)}
          </Card>
        </Col>
        <Col span={24} xxl={6} lg={12}>
          <Card>
            <Statistic
              loading={!transactions || loading}
              title="Прибыль"
              value={
                transactions.length
                  ? `${(transactionsSum - information).toLocaleString("ru-RU", {
                      style: "currency",
                      currency: "RUB",
                    })} `
                  : ""
              }
            />
            {renderMony(transactionsSum - information)}
          </Card>
        </Col>
        <Col span={24} xxl={6} lg={12}>
          <Card>
            <Statistic
              loading={!transactions || loading}
              title="БАНК"
              value={
                informationBank
                  ? `${informationBank.toLocaleString("ru-RU", {
                      style: "currency",
                      currency: "RUB",
                    })} `
                  : ""
              }
            />
            {renderMony(informationBank)}
          </Card>
        </Col>
        <Col span={24} xxl={6} lg={12}>
          <Card>
            <Statistic
              loading={!transactions || loading}
              title="Прибыль - БАНК"
              value={
                transactions.length
                  ? `${(transactionsSum - information - informationBank).toLocaleString("ru-RU", {
                      style: "currency",
                      currency: "RUB",
                    })} `
                  : ""
              }
            />
            {renderMony(transactionsSum - information - informationBank)}
          </Card>
        </Col>
        <Col span={24} xxl={6} lg={12} />
        <Col span={24} xxl={6} lg={12} />
        <Col span={24} xxl={6} lg={12}>
          <Card>
            <Statistic loading={loading} title="Всего пользователей" value={statistics.users} />
          </Card>
        </Col>
        <Col span={24} xxl={6} lg={12}>
          <Card>
            <Statistic loading={loading} title="Всего анкет" value={statistics.profiles.all} />
          </Card>
        </Col>
        <Col span={24} xxl={6} lg={12}>
          <Card>
            <Statistic
              loading={loading}
              title="Анкет женщин"
              value={statistics.profiles.female}
              suffix={
                <Fragment>
                  <Typography.Text>&#x2022; </Typography.Text>
                  <Typography.Text type="success">{statistics.online.female}</Typography.Text>
                </Fragment>
              }
            />
          </Card>
        </Col>
        <Col span={24} xxl={6} lg={12}>
          <Card>
            <Statistic
              loading={loading}
              title="Анкет мужчин"
              value={statistics.profiles.male}
              suffix={
                <Fragment>
                  <Typography.Text>&#x2022; </Typography.Text>
                  <Typography.Text type="success">{statistics.online.male}</Typography.Text>
                </Fragment>
              }
            />
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
