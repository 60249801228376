import "./index.scss";

import { Spin } from "antd";
import { FC } from "react";

export const Spinner: FC = () => (
  <div className="spinner">
    <Spin size="large" />
  </div>
);
