import { Button, DatePicker, Form, Input } from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import { FC } from "react";

import { request } from "../../../utils/request";
import cls from "./style.module.scss";

interface IModalAddTag {
  buttonEdit?: string;
  buttonAdd?: string;
  nameField?: string;
  placeholderName?: string;
  placeholderDescription?: string;
  getTitle?: boolean;
  setIdMarket?: boolean;
  descriptionField?: string;
  isModalVisible: {
    title?: string;
    imageUrl?: string;
    imageMobileUrl?: string;
    url?: string;
    id?: number;
    active?: boolean;
    inNewTab?: boolean;
    displayDescription?: boolean;
    titleInMarket?: string;
    [e: string]: unknown;
  } | null;
  setIsModalVisible: (e: any) => void;
  getAll: () => void;
}

const Content: FC<any> = ({ onFinish, isModalVisible }) => {
  return (
    <div className={cls.wrpper}>
      <div className={cls.wraapperAddFilling}>
        <Form
          id="submitModal"
          labelCol={{
            span: 5,
          }}
          initialValues={
            isModalVisible.id
              ? {
                  amountOfCosts: isModalVisible.amountOfCosts,
                  text: isModalVisible?.text || "",
                  date: isModalVisible?.date ? moment(isModalVisible.date) : "",
                }
              : undefined
          }
          wrapperCol={{}}
          labelAlign="left"
          layout="horizontal"
          onFinish={onFinish}
          size="middle"
        >
          <Form.Item
            rules={[{ required: true, message: "Поле не должно быть пустым" }]}
            required
            name="amountOfCosts"
            colon={false}
            label="Сумма"
          >
            <Input type="number" placeholder="введите сумму" />
          </Form.Item>

          <Form.Item name="text" colon={false} label="Описание расхода">
            <Input placeholder="Описание расхода" />
          </Form.Item>

          <Form.Item name="date" colon={false} label="Дата расхода">
            <DatePicker format="DD.MM.YYYY" placeholder="Описание расхода" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

const ModalAdd: FC<IModalAddTag> = ({ isModalVisible, setIsModalVisible, buttonEdit, buttonAdd, getAll }) => {
  const handleOk = (): void => {
    setIsModalVisible(null);
  };
  const handleCancel = (): void => {
    setIsModalVisible(null);
  };

  const onFinish = async (e: any): Promise<void> => {
    if (isModalVisible?.id) {
      await request.post("/administrator-bank-information", {
        ...e,
        id: isModalVisible?.id,
        amountOfCosts: Number(e.amountOfCosts),
      });
    } else {
      await request.post("/administrator-bank-information", { ...e, amountOfCosts: Number(e.amountOfCosts) });
    }
    getAll();
    handleOk();
  };

  return (
    <Modal
      footer={[
        <Button type="default" key="reset" onClick={handleOk}>
          Отмена
        </Button>,
        <Button type="primary" form="submitModal" key="save" htmlType="submit">
          {isModalVisible?.id ? buttonEdit : buttonAdd}
        </Button>,
      ]}
      centered
      maskClosable
      keyboard
      destroyOnClose
      className={cls.modalStyle}
      title="Расходы"
      open={!!isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Content isModalVisible={isModalVisible} onFinish={onFinish} />
    </Modal>
  );
};

ModalAdd.defaultProps = {
  buttonEdit: "Сохранить",
  buttonAdd: "Сохранить",
};

export default ModalAdd;
